import { Grid, Typography } from "@mui/material";
import { tss } from "tss-react";
import TvTimeline from "./shared/TvTimeline";
import resumeData from "../utils/resumeData"
import PermIdentity from '@mui/icons-material/PermIdentity'
import TvButton from "./shared/TvButton";
import { CONTAINER_STYLE } from "../constants";

const useStyles = tss.create({
    profile: {
        backgroundColor: "white",
        width: '100%',
        display: 'inline-block',
        ...CONTAINER_STYLE
    },
    profileName: {
        lineHeight: '18px',
        padding: '20px'
    },
    name: {
        textTransform: 'uppercase',
        fontSize: '17px',
        fontWeight: 'bold'
    },
    title: {
        textTransform: 'uppercase',
        fontSize: '13px',
        color: '#777'
    },
    profileImageContainer: {
        marginTop: '-25px',
        clipPath: 'polygon(0 9%, 100% 0, 100% 94%, 0% 100%)',
        WebkitClipPath: 'polygon(0 9%, 100% 0, 100% 94%, 0% 100%)'
    },
    profileImage: {
        width: '100%'
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '10px'
    },
    profileTimeline: {
        marginTop: '-50px'
    }
})

function Profile() {
    const { classes } = useStyles()
    return (
        <Grid container className={classes.profile}>
            <Grid item className={classes.profileName}>
                <Typography className={classes.name}>{resumeData.name}</Typography>
                <Typography className={classes.title}>{resumeData.position}</Typography>
            </Grid>
            <Grid item className={classes.profileImageContainer}>
                <img className={classes.profileImage} src={require('../media/ProfilePic.jpg')} alt="" />
            </Grid>
            <Grid>
                <Grid className={classes.profileTimeline}>
                    <TvTimeline title='' parent='PROFILE' icon={<PermIdentity/>} items={resumeData.profile}/>
                </Grid>
                <Grid className={classes.buttonContainer}>
                    <TvButton text="Download PDF" link='https://drive.google.com/file/d/1URjIF0B9iMMxZKMp3cEvL4WgQyRyQs6Z/view?usp=drive_link' icon={<PermIdentity />}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Profile;