import { Grid, Typography } from "@mui/material";
import { tss } from "tss-react";
import resumeData from "../../utils/resumeData";
import SectionTitle from "../shared/SectionTitle";
import TvButton from "../shared/TvButton" 
import RecommendIcon from '@mui/icons-material/Recommend'

const useStyles = tss.create({
    darkSection: {
        padding: '24px',
        justifyContent: 'center',
        backgroundColor: '#EEE',
    },
    skillGridItem: {
        backgroundColor: '#FFF',
        borderRadius: '5px',
        minHeight: '100%',
        padding: '20px',
    },
    devSkillGridItem: {
        backgroundColor: '#EEE',
        borderRadius: '5px',
        minHeight: '100%',
        "@media (max-width: 600px)": {
            padding: '10px',
            overflow: 'hidden'
        },
        "@media (min-width: 600px)": {
            padding: '20px',
        }
    },
    skillDescription: {
        color: '#707070 !important',
        paddingTop: '10px !important'
    }
})

function Home() {
    const { classes } = useStyles()
    return (
        <Grid>
            <Grid container padding='24px'>
                <SectionTitle text='About Me' />
                <Grid item xs={12} paddingLeft={'10px'}>
                    <Typography variant='body2'>{resumeData.summary}</Typography>
                    <br />
                    <TvButton 
                            link='https://www.linkedin.com/in/thomasvilesjr/details/recommendations/'
                            text='LinkedIn Recommendations'
                            icon={<RecommendIcon />}

                        />
                </Grid>
            </Grid>
            <Grid container className={classes.darkSection}>
                <SectionTitle text='Soft Skills' />
                <Grid container xs={12} spacing={2}>
                    {resumeData.softSkills.map((skill) => (
                        <Grid item xs={12} md={6} lg={3}>
                            <Grid className={classes.skillGridItem}>
                                <Typography>{skill.icon}</Typography>
                                <Typography>{skill.name}</Typography>
                                <Typography variant='caption' className={classes.skillDescription}>{skill.description}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container padding='24px' justifyContent='center'>
                <SectionTitle text='Dev Skill' />
                <Grid container xs={12} spacing={2}>
                    {resumeData.devSkills.map((skill) => (
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <Grid className={classes.devSkillGridItem}>
                                <Typography >{skill.icon}</Typography>
                                <Typography>{skill.name}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;