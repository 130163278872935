import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import HouseIcon from '@mui/icons-material/House'
import EmailIcon from '@mui/icons-material/Email'
import TvButton from './shared/TvButton' 
import { tss } from 'tss-react'
import resumeData from '../utils/resumeData'
import { ICON_BACKGROUND_COLOR, ICON_COLOR, CONTAINER_STYLE } from '../constants'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const useStyles = tss.create({
    appBar: {
        position: 'static',
        backgroundColor: `white`,
        paddingLeft:'0 !important',
        paddingRight: '10px',
        fontColor: 'black',
        ...CONTAINER_STYLE
    },
    fullToolbar: {
        justifyContent: 'space-between',
        padding:'0 !important',
    },
    homeButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '64px',
        width: '64px',
        overflow: 'hidden',
        borderRadius: '5px 0px 0px 5px',
        backgroundColor: ICON_BACKGROUND_COLOR,
        color: ICON_COLOR
    },
    homeAndLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "@media (max-width: 1025px)": {
            display: "none",
        }
    },
    socials: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    linkButtons: {
        color: 'black'
    },
    socialButtons: {
        color: 'black',
        "@media (max-width: 400px)": {
            display: "none",
        }
    },
    dropDownMenu: {
        "@media (min-width:1025px)": {
            display: "none",
        }
    }
})

export type page = {
    title: string,
    href: string
}

const pages: page[] = [
    { title: 'Resume', href: '/resume' }, 
    { title: 'Portfolio', href: '/portfolio' }
]

function Header() {
    const { classes } = useStyles()
    const navigate = useNavigate()

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (link?: string) => {
        setAnchorElNav(null)
        if (link) { navigate(link) }
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.fullToolbar}>
                <Box className={classes.homeAndLinks}>
                    <Typography
                        component={Link}
                        onClick={() => handleCloseNavMenu("/")}
                        className={classes.homeButton}
                    >
                        <HouseIcon />
                    </Typography>

                    <Box className={classes.dropDownMenu}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={() => handleCloseNavMenu()}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.title} onClick={() => handleCloseNavMenu(page.href)}>
                                    <Button
                                        key={page.title}
                                        onClick={() => handleCloseNavMenu(page.href)}
                                        className={classes.linkButtons}
                                    >
                                        {page.title}
                                    </Button>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box className={classes.links}>
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                onClick={() => handleCloseNavMenu(page.href)}
                                className={classes.linkButtons}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>
                </Box>

                <Box className={classes.socials}>
                    {resumeData.socials.map((socialData) => (
                        <Button
                            key={socialData.text}
                            href={socialData.link}
                            target='_blank'
                            className={classes.socialButtons}
                        >
                            {socialData.icon}
                        </Button>
                    ))}
                    <TvButton text='Contact' link='mailto:Tcv720@gmail.com' icon={<EmailIcon />}/>
                </Box>
            </Toolbar>
        </AppBar>
    )
}
export default Header