import { Button, Typography } from "@mui/material";
import { ReactElement } from "react";
import { tss } from "tss-react";
import { ICON_COLOR, ICON_BACKGROUND_COLOR } from "../../constants";

const useStyles = tss.create({
    button: {
        backgroundColor: `${ICON_BACKGROUND_COLOR}`,
        borderRadius: '50px',
        padding: '1px 12px',
        minWidth: '100px',
    },
    formattedIcon: {
        color: `${ICON_COLOR}`,
        backgroundColor: 'white',
        borderRadius: '50px',
        height: '27px',
        width: '27px',
        lineHeight: '29px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonText: {
        color: `${ICON_COLOR}`,
        fontSize: '14px',
        textTransform: 'none',
        textAlign: 'center',
        marginRight: '5px',
        marginLeft: '5px',
        fontWeight: 'bold',
        lineHeight: '35px'
    }
})

export type TvButtonProps = {
    text: string,
    link?: string,
    icon?: ReactElement
}

function TvButton({text, icon, link}: TvButtonProps) {
    const { classes } = useStyles()
    const formattedIcon = <div className={classes.formattedIcon}> {icon} </div>
    return (
        <Button 
            className={classes.button} 
            href={link ? link : ''}
            endIcon={icon ? (formattedIcon) : null}
            target='_blank'
        > 
            <Typography className={classes.buttonText}>{text}</Typography>
        </Button>
    );
}

export default TvButton;