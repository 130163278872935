import React from 'react'
import Box from '@mui/material/Box'
import { tss } from 'tss-react'
import { Typography } from '@mui/material'
import resumeData from '../utils/resumeData'
import { CONTAINER_STYLE } from '../constants'

const useStyles = tss.create({
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'black',
        minHeight: '70px',
        ...CONTAINER_STYLE
    },
    footerText: {
        color: '#9c9c9c',
        padding: '20px 25px'
    },
    textLeft: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'white'
    },
    textRight: {
        fontSize: '13px'
    },
    link: {
        textDecoration: 'none',
        color: 'white'
    }
})

function Footer() {
    const { classes } = useStyles()

    return (
        <Box className={classes.footer}>
            <Box className={classes.footerText}>
                <Typography className={classes.textLeft}>{resumeData.name}</Typography>
            </Box>

            <Box className={classes.footerText}>
                <Typography className={classes.textRight}>@2024 All Rights Reserved </Typography>
                <Typography className={classes.textRight}>Template cloned from <a href='https://themeforest.net/user/tavonline' className={classes.link} target='_blank' rel='noreferrer'>Tavonline</a></Typography>
            </Box>
        </Box>
    )
}
export default Footer