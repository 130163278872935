import { Grid, Typography } from "@mui/material";
import { tss } from "tss-react";

const useStyles = tss.create({
    sectionTitle: {
        position: 'relative',
        marginBottom: '10px',
        width: 'fit-content'
    },
    customUnderline: {
        backgroundColor: '#ffb100',
        position: "absolute",
        height: '7px',
        opacity: '0.4',
        width: '100%',
        marginTop: '-10px'
    },
})

function SectionTitle({text}: {text: string}) {
    const { classes } = useStyles()
    return (
        <Grid item width='100%'>
            <Grid item className={classes.sectionTitle}>
                <Typography variant="h5">
                    {text}
                </Typography>
                <span className={classes.customUnderline}></span>
            </Grid>
        </Grid>
    );
}

export default SectionTitle;