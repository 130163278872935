import React from 'react';
import { Container, Grid } from '@mui/material';
import Profile from './components/Profile';
import Footer from './components/Footer';
import Header from './components/Header';
import Portfolio from './components/mainContent/Portfolio';
import Resume from './components/mainContent/Resume';
import Home from './components/mainContent/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { tss } from 'tss-react'
import { CONTAINER_STYLE } from './constants'

const useStyles = tss.create({
  app: {
    marginTop: "30px",
  },
  content: {
    backgroundColor: 'white',
    margin: '20px 0px',
    ...CONTAINER_STYLE
  }
})

function App() {
  const { classes } = useStyles();
  const signedIn = true

  return signedIn ? (
    <Container className={classes.app}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <Profile />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Router>
          <Header />
            <Grid className={classes.content}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/resume' element={<Resume />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='*' element={<Home />} />
              </Routes>
            </Grid>
          </Router>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div />
  )
}

export default App;
