import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

function TvYoutube({ videoId }: { videoId: string }) {
    const [dimensions, setDimensions] = useState({ height: 270, width: 480 });

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            
            const newWidth = Math.min(windowWidth * 0.8, 480); 
            const newHeight = (newWidth / 16) * 9; 
            setDimensions({ width: newWidth, height: newHeight });
        };

        handleResize();
        window?.addEventListener('resize', handleResize);

        return () => {
            window?.removeEventListener('resize', handleResize);
        };
    }, []);

    // Handle when the video ends
    const onEnd = (event: any) => {
        console.log('Video ended:', event);
    };

    return (
        <YouTube videoId={videoId} opts={{ ...dimensions }} onEnd={onEnd} />
    );
}

export default TvYoutube;
