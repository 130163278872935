import { Grid } from "@mui/material";
import resumeData from "../../utils/resumeData";
import SectionTitle from "../shared/SectionTitle";
import TvTimeline from "../shared/TvTimeline";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import SchoolIcon from '@mui/icons-material/School'

function Resume() {
    return (
        <Grid padding={'24px'}>
            <SectionTitle text='Resume' />
            <Grid container >
                <Grid item xs={12} sm={6}>
                    <TvTimeline parent='RESUME' icon={<WorkHistoryIcon />} items={resumeData.workHistory} title='Work History' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TvTimeline parent='RESUME' icon={<SchoolIcon />} items={resumeData.education} title='Education History' />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Resume;