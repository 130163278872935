import { ReactElement } from "react"
import { Typography } from "@mui/material"
import { tss } from "tss-react"
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { ICON_BACKGROUND_COLOR, ICON_COLOR } from '../../constants'
import { TimelineData } from "../../utils/resumeData"

const useStyles = tss.create({
    oppositeContent: {
        display: 'none'
    },
    timelineHeader: {},
    timelineHeaderConnector: {
        backgroundColor: '#EEE !important',
        height: '10px'
    },
    timelineHeaderDot: {
        color: `${ICON_COLOR} !important`,
        backgroundColor: `${ ICON_BACKGROUND_COLOR } !important`,
        fontSize: 'small !important',
        padding: '12px !important'
    },
    timelineHeaderText: {
        paddingTop: '7px'
    },
    timelineItems: {
        paddingLeft: '21px',
        minHeight: '20px !important',
        marginBottom: '20px'
    },
    timelineItemContent: {
        fontSize: '15px',
        color: '#707070'
    },
    timelineItemLabel: {
        textTransform: 'uppercase',
        marginRight: '5px',
        fontSize: '15px',
        fontWeight: 'bold'
    },
    experienceItemContent: {
        fontSize: '15px',
        color: '#707070',
        padding: '5px'
    },
    experienceItemDate: {
        fontSize: '12px',
        color: '#707070'
    },
    experienceItemLabel: {
        textTransform: 'uppercase',
        marginRight: '5px',
        fontSize: '13px',
        fontWeight: 'bold'
    },
    timelineItemsDot: {
        padding: '3px !important',
        borderColor: `${ ICON_BACKGROUND_COLOR } !important`,
        backgroundColor: 'white !important'
    },
    timelineContent: {
        marginTop: '-4px'
    }
})

export type ITvTimeline = {
    parent: 'PROFILE' | 'RESUME' | 'PORTFOLIO'
    title: string | '',
    items: TimelineData[],
    icon: ReactElement
}

function ProfileContent({ label, content }: { label: string, content: string }) {
    const { classes } = useStyles()

    return (
        <TimelineContent className={classes.timelineContent}>
            <Typography className={classes.timelineItemLabel}>{label}</Typography>
            <Typography className={classes.timelineItemContent}>{content}</Typography>
        </TimelineContent>
    )
}

function ExperienceContent({ label, dates, content }: { label: string, dates: string, content: string }) {
    const { classes } = useStyles()

    return (
        <TimelineContent className={classes.timelineContent}>
            <Typography className={classes.experienceItemLabel}>{label}</Typography>
            <Typography className={classes.experienceItemDate}>{dates}</Typography>
            <Typography className={classes.experienceItemContent}>{content}</Typography>
        </TimelineContent>
    )
}

function TvTimeline({ parent, title, items, icon }: ITvTimeline) {
    const { classes } = useStyles()

    return (
        <Timeline>
            <TimelineItem className={classes.timelineHeader}>
                <TimelineOppositeContent className={classes.oppositeContent} />
                <TimelineSeparator>
                    <TimelineDot className={classes.timelineHeaderDot}>
                        {icon}
                    </TimelineDot>
                    <TimelineConnector className={classes.timelineHeaderConnector} />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography variant="h6" className={classes.timelineHeaderText}>
                        {title}
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            {items.map((item, i) => (
                <TimelineItem className={classes.timelineItems}>
                    <TimelineOppositeContent className={classes.oppositeContent} />
                    <TimelineSeparator>
                        <TimelineDot className={classes.timelineItemsDot} />
                        {(i !== items.length - 1) && (<TimelineConnector />)}
                    </TimelineSeparator>
                    {parent === 'PROFILE' && 
                        <ProfileContent 
                            label={item.name} 
                            content={item.description} 
                        />
                    }
                    {parent === 'RESUME' && 
                        <ExperienceContent 
                            label={item.name} 
                            dates={`${item.startDate} - ${item.endDate ? item.endDate : 'Present'}`} 
                            content={item.description} 
                        />
                    }
                </TimelineItem>
            ))}
        </Timeline>
    )
}

export default TvTimeline